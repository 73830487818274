*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

:root {
  --corePrimaryColor: orange;
}

body {
  --tw-text-opacity: 1;
  color: rgb(35 38 47 / var(--tw-text-opacity));
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.h1 {
  font-size: 1.75rem;
  line-height: 1.25;
}

@media (min-width: 1024px) {
  .h1 {
    font-size: 2.25rem;
    line-height: 1.375;
  }
}

@media (min-width: 1280px) {
  .h1 {
    font-size: 4rem;
    line-height: 1.3;
  }
}

.h1 {
  white-space: normal;
  letter-spacing: .05em;
  font-weight: 700;
}

.h2 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
}

@media (min-width: 768px) {
  .h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (min-width: 1024px) {
  .h2 {
    line-height: 1.4;
  }
}

@media (min-width: 1280px) {
  .h2 {
    font-size: 2.625rem;
  }
}

.h2 {
  letter-spacing: .05em;
}

.h4 {
  letter-spacing: .05em;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

@media (min-width: 1024px) {
  .h4 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.btn-primary {
  border-radius: 9999px;
  padding: .875rem 2.25rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

@media (min-width: 1024px) {
  .btn-primary {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.btn-primary {
  transition: color .3s cubic-bezier(.4, 0, .2, 1) .15s, background-color .3s cubic-bezier(.4, 0, .2, 1) .15s, border-color .3s cubic-bezier(.4, 0, .2, 1) .15s, text-decoration-color .3s cubic-bezier(.4, 0, .2, 1) .15s, fill .3s cubic-bezier(.4, 0, .2, 1) .15s, stroke .3s cubic-bezier(.4, 0, .2, 1) .15s, opacity .3s cubic-bezier(.4, 0, .2, 1) .15s, box-shadow .3s cubic-bezier(.4, 0, .2, 1) .15s, transform .3s cubic-bezier(.4, 0, .2, 1) .15s, filter .3s cubic-bezier(.4, 0, .2, 1) .15s, -webkit-backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .15s, backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .15s, -webkit-text-decoration-color .3s cubic-bezier(.4, 0, .2, 1) .15s, -webkit-backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .15s;
}

.btn-primary:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.btn-primary {
  letter-spacing: .05rem;
  color: #fff;
  white-space: nowrap;
  background: linear-gradient(131.73deg, var(--corePrimaryColor) 0%, #ffcb37 100%);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  box-shadow: 0 3px 30px #49494926;
}

.btn-black {
  border-radius: 9999px;
  padding: .875rem 2.25rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

@media (min-width: 1024px) {
  .btn-black {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.btn-black {
  transition: color .3s cubic-bezier(.4, 0, .2, 1) .15s, background-color .3s cubic-bezier(.4, 0, .2, 1) .15s, border-color .3s cubic-bezier(.4, 0, .2, 1) .15s, text-decoration-color .3s cubic-bezier(.4, 0, .2, 1) .15s, fill .3s cubic-bezier(.4, 0, .2, 1) .15s, stroke .3s cubic-bezier(.4, 0, .2, 1) .15s, opacity .3s cubic-bezier(.4, 0, .2, 1) .15s, box-shadow .3s cubic-bezier(.4, 0, .2, 1) .15s, transform .3s cubic-bezier(.4, 0, .2, 1) .15s, filter .3s cubic-bezier(.4, 0, .2, 1) .15s, -webkit-backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .15s, backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .15s, -webkit-text-decoration-color .3s cubic-bezier(.4, 0, .2, 1) .15s, -webkit-backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .15s;
}

.btn-black:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.btn-black {
  letter-spacing: .05rem;
  color: #fff;
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: rgb(35 38 47 / var(--tw-bg-opacity));
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.badge-primary {
  letter-spacing: .05em;
  border-radius: 9999px;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1rem;
}

@media (min-width: 1024px) {
  .badge-primary {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.badge-primary {
  text-transform: uppercase;
  background: linear-gradient(91.28deg, #ed990033 11.48%, #ffb67433 53.89%, #f003 133.02%);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 18px;
  display: inline-flex;
}

.badge-red {
  letter-spacing: .05em;
  border-radius: 9999px;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1rem;
}

@media (min-width: 1024px) {
  .badge-red {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.badge-red {
  text-transform: uppercase;
  background: linear-gradient(91.28deg, #ed9900 11.48%, #ffb674 53.89%, red 133.02%);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 18px;
  display: inline-flex;
}

.promo_count {
  align-items: baseline;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2rem;
  display: flex;
}

@media (min-width: 1280px) {
  .promo_count {
    font-size: 42px;
  }
}

.promo_count {
  --tw-text-opacity: 1;
  color: rgb(255 165 0 / var(--tw-text-opacity));
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.faq {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  padding-top: 1.5rem;
}

.faq:first-of-type {
  border-width: 0;
}

.accordion {
  width: 100%;
  text-align: left;
  outline-width: 0;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.accordion:hover {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 165 0 / var(--tw-text-opacity));
}

.accordion.active {
  --tw-text-opacity: 1;
  color: rgb(255 165 0 / var(--tw-text-opacity));
}

.accordion.active .accordion_icon {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-text-opacity: 1;
  color: rgb(255 165 0 / var(--tw-text-opacity));
}

.accordion:hover .accordion_icon {
  --tw-text-opacity: 1;
  color: rgb(255 165 0 / var(--tw-text-opacity));
}

.accordion.active + .panel {
  margin-bottom: 1.5rem;
}

.panel {
  max-height: 0;
  transition: max-height .2s ease-out;
  overflow: hidden;
}

.accordion_icon {
  height: 1.25rem;
  width: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  flex-shrink: 0;
  margin-top: .25rem;
  margin-left: 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.faq-page {
  background-position: 50% 4rem;
}

@media (min-width: 1024px) {
  .faq-page {
    background-position: top;
  }
}

.faq-page {
  background-image: url("creator-bg-top.0f950139.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.top-16 {
  top: 4rem;
}

.-bottom-7 {
  bottom: -1.75rem;
}

.-left-2 {
  left: -.5rem;
}

.-right-2 {
  right: -.5rem;
}

.z-10 {
  z-index: 10;
}

.z-0 {
  z-index: 0;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-1\.5 {
  margin-left: .375rem;
  margin-right: .375rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mt-auto {
  margin-top: auto;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-3\.5 {
  margin-bottom: .875rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-8 {
  height: 2rem;
}

.h-4 {
  height: 1rem;
}

.h-7 {
  height: 1.75rem;
}

.h-96 {
  height: 24rem;
}

.h-full {
  height: 100%;
}

.h-5 {
  height: 1.25rem;
}

.h-\[342px\] {
  height: 342px;
}

.h-\[50rem\] {
  height: 50rem;
}

.max-h-full {
  max-height: 100%;
}

.max-h-96 {
  max-height: 24rem;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-full {
  min-height: 100%;
}

.w-screen {
  width: 100vw;
}

.w-36 {
  width: 9rem;
}

.w-full {
  width: 100%;
}

.w-48 {
  width: 12rem;
}

.w-8 {
  width: 2rem;
}

.w-4 {
  width: 1rem;
}

.w-\[138px\] {
  width: 138px;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.shrink-0 {
  flex-shrink: 0;
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.animate-bounce {
  animation: 1s infinite bounce;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-8 {
  gap: 2rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-x-8 {
  -moz-column-gap: 2rem;
  column-gap: 2rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-current {
  border-color: currentColor;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgb(255 165 0 / var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 249 232 / var(--tw-bg-opacity));
}

.bg-black\/70 {
  background-color: #000000b3;
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-\[url\(\'img\/underline\.svg\'\)\] {
  background-image: url("underline.33ab116d.svg");
}

.bg-\[url\(\'img\/bg-main-1\.webp\'\)\] {
  background-image: url("bg-main-1.b90bfceb.webp");
}

.bg-\[url\(\'img\/bg-main-3\.png\'\)\] {
  background-image: url("bg-main-3.ed162461.png");
}

.bg-\[url\(\'img\/creator-1\.jpg\'\)\] {
  background-image: url("creator-1.c778e23e.jpg");
}

.bg-\[url\(\'img\/creator-2\.jpg\'\)\] {
  background-image: url("creator-2.1de7d8ba.jpg");
}

.bg-\[url\(\'img\/creator-3\.jpg\'\)\] {
  background-image: url("creator-3.489ddc53.jpg");
}

.bg-\[url\(\'img\/creator-4\.jpg\'\)\] {
  background-image: url("creator-4.036633eb.jpg");
}

.bg-\[url\(\'img\/creator-5\.jpg\'\)\] {
  background-image: url("creator-5.98f58456.jpg");
}

.bg-\[url\(\'img\/creator-6\.jpg\'\)\] {
  background-image: url("creator-6.22e9e169.jpg");
}

.bg-\[url\(\'img\/bg-main-5-mobile\.webp\'\)\] {
  background-image: url("bg-main-5-mobile.350d057e.webp");
}

.from-orange-400 {
  --tw-gradient-from: orange;
  --tw-gradient-to: #ffa50000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300 {
  --tw-gradient-from: #fdba74;
  --tw-gradient-to: #fdba7400;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-orange-400 {
  --tw-gradient-to: #ffa50000;
  --tw-gradient-stops: var(--tw-gradient-from), orange, var(--tw-gradient-to);
}

.to-orange-500 {
  --tw-gradient-to: #ffcb37;
}

.to-orange-300 {
  --tw-gradient-to: #fdba74;
}

.bg-\[length\:100\%_auto\] {
  background-size: 100%;
}

.bg-cover {
  background-size: cover;
}

.bg-\[length\:auto_100\%\] {
  background-size: auto 100%;
}

.bg-center {
  background-position: center;
}

.bg-top {
  background-position: top;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pb-96 {
  padding-bottom: 24rem;
}

.text-center {
  text-align: center;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.leading-8 {
  line-height: 2rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-\[1\.375rem\] {
  line-height: 1.375rem;
}

.leading-\[1\.4\] {
  line-height: 1.4;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(255 165 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.opacity-50 {
  opacity: .5;
}

.opacity-100 {
  opacity: 1;
}

.shadow-\[0_8px_40px_rgba\(0\,0\,0\,0\.05\)\] {
  --tw-shadow: 0 8px 40px #0000000d;
  --tw-shadow-colored: 0 8px 40px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[8px_8px_0_rgba\(255\,165\,0\,0\.24\)\] {
  --tw-shadow: 8px 8px 0 #ffa5003d;
  --tw-shadow-colored: 8px 8px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.hover\:border-0:hover {
  border-width: 0;
}

.hover\:bg-orange-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 249 232 / var(--tw-bg-opacity));
}

@media (min-width: 375px) {
  .xs\:h-full {
    height: 100%;
  }

  .xs\:w-1\/2 {
    width: 50%;
  }
}

@media (min-width: 640px) {
  .sm\:h-\[225px\] {
    height: 225px;
  }

  .sm\:w-\[190px\] {
    width: 190px;
  }
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }
}

@media (min-width: 1024px) {
  .lg\:relative {
    position: relative;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:mt-32 {
    margin-top: 8rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:ml-32 {
    margin-left: 8rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:-mt-36 {
    margin-top: -9rem;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mb-1 {
    margin-bottom: .25rem;
  }

  .lg\:mt-14 {
    margin-top: 3.5rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:mt-40 {
    margin-top: 10rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:h-8 {
    height: 2rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-72 {
    width: 18rem;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:max-w-xl {
    max-width: 36rem;
  }

  .lg\:max-w-\[547px\] {
    max-width: 547px;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:gap-5 {
    gap: 1.25rem;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-x-4 {
    -moz-column-gap: 1rem;
    column-gap: 1rem;
  }

  .lg\:place-self-center {
    place-self: center;
  }

  .lg\:whitespace-nowrap {
    white-space: nowrap;
  }

  .lg\:bg-white\/20 {
    background-color: #fff3;
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .lg\:bg-\[url\(\'img\/bg-main-5\.webp\'\)\] {
    background-image: url("bg-main-5.3b9d3151.webp");
  }

  .lg\:bg-cover {
    background-size: cover;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .lg\:pl-24 {
    padding-left: 6rem;
  }

  .lg\:pt-2\.5 {
    padding-top: .625rem;
  }

  .lg\:pt-2 {
    padding-top: .5rem;
  }

  .lg\:pt-36 {
    padding-top: 9rem;
  }

  .lg\:pl-12 {
    padding-left: 3rem;
  }

  .lg\:pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:pt-16 {
    padding-top: 4rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pl-16 {
    padding-left: 4rem;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pt-3 {
    padding-top: .75rem;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:leading-8 {
    line-height: 2rem;
  }

  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .lg\:hover\:bg-white\/30:hover {
    background-color: #ffffff4d;
  }
}

@media (min-width: 1280px) {
  .xl\:mt-28 {
    margin-top: 7rem;
  }

  .xl\:inline {
    display: inline;
  }

  .xl\:max-h-full {
    max-height: 100%;
  }

  .xl\:border-l {
    border-left-width: 1px;
  }

  .xl\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:pl-16 {
    padding-left: 4rem;
  }

  .xl\:pt-36 {
    padding-top: 9rem;
  }

  .xl\:pb-44 {
    padding-bottom: 11rem;
  }

  .xl\:pl-8 {
    padding-left: 2rem;
  }

  .xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .xl\:text-\[3\.375rem\] {
    font-size: 3.375rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:container {
    width: 100%;
  }

  @media (min-width: 375px) {
    .\32 xl\:container {
      max-width: 375px;
    }
  }

  @media (min-width: 640px) {
    .\32 xl\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .\32 xl\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .\32 xl\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .\32 xl\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .\32 xl\:container {
      max-width: 1536px;
    }
  }

  .\32 xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .\32 xl\:w-52 {
    width: 13rem;
  }

  .\32 xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .\32 xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .\32 xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .\32 xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .\32 xl\:pb-24 {
    padding-bottom: 6rem;
  }

  .\32 xl\:pl-24 {
    padding-left: 6rem;
  }

  .\32 xl\:pr-16 {
    padding-right: 4rem;
  }

  .\32 xl\:pt-1 {
    padding-top: .25rem;
  }

  .\32 xl\:pr-12 {
    padding-right: 3rem;
  }

  .\32 xl\:pl-12 {
    padding-left: 3rem;
  }
}

/*# sourceMappingURL=faq.16847b86.css.map */
